.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.map-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
}

.location-property {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #e9eefb;
  padding: 5px;
  width:100%;
}

.location-property-key {
  font-weight: 600;
  text-transform: uppercase;
  width: 300px;
  text-align: start;
  margin-right: 10px;
}

.location-property-value {
  width:300px;
  text-align: start;
}

.location-title {
  width: 100%;
  text-transform: uppercase;
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b2b6bd;
  font-weight: 800;
}

.value-not-set {
  color: #960808;
  font-style: italic;
}

.sidebar {
  background: white;
  padding: 0 10px 5px 10px;
  overflow-y: scroll;
}

.ui.form .field {
  margin: 0 0 0.3em !important;
}

.ui.header {
  border: none;
  margin: calc(0.8rem - .14285714em) 0 0.5rem !important;
}

.ui.segment {
  padding: 0 !important;
}
.location-pointer {
  width: 15px;
  height: 15px;
  cursor: pointer;
  border-radius: 50%;
  border: solid white 1px;
}

.main-container{
  display: flex;
}
.map-container {
  width: 75%;
  height: 100vh;
}
.controls-container{
  width: 25%;
  height: 100vh;
  overflow-y: scroll;
}
.navigation-control {
  position: absolute;
  left: 10px;
  top: 10px;
}
.stories-control {
  margin-top: 10px;
}
.stories-container {
  position: absolute;
  width: 600px;
  background: white;
  padding: 10px;
  text-align: start;
  top: 55px;
  left: 10px;
  max-height: 90vh;
  overflow-y: scroll;
  z-index: 9;
  opacity: 0.85;
}
.story-title {
  padding: 5px;
}
.story-title:hover {
  background: #e9eefb;
}
.map-location-list{
  width: 284px;
  background: white;
  top:100px;
  overflow-y: scroll;
  text-align: start;
  padding: 5px;
  max-height: 500px;
  cursor: auto;
  box-shadow: 0 0 4px rgba(0,0,0,.15);
  margin: 24px;
}

.ui.form {
  font-size: 0.75rem !important;
}

@media only screen and (max-width: 800px) {
  .main-container{
    flex-direction: column
  }
  .map-container {
    width: 100%;
    height: 60vh;
  }
  .controls-container {
    width: 100%;
  }

  .stories-container {
    width: 100%;
  }

  .story-detail {
    height: 315px;
    overflow-y: scroll;
  }
}